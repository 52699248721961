// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_5kAaFH6p76tz5mo5kO",
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/9AQaFx6RogjYffifZr",

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Image Face Restoration AI 🎭",
    HOME_PAGE_TITLE: "Home - Image Face Restoration AI 🎭",
    DASHBOARD_PAGE_TITLE: "Home - Image Face Restoration AI 🎭",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Image Face Restoration AI 🎭",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to restore quality of face images, choose your image file and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "img_face_restoration_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/img-face-restoration-ai-1",

    // ... add more strings as needed
};



export default strings;